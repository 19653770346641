<template>
  <common-form
    ref="form"
    url="menu"
    :edit="edit"
    :items="items"
    @success="$emit('success')"></common-form>
</template>
<script>
  export default {
    data() {
      return {
        type:0,   //0菜单，1按钮
        edit:false,
        menuTreeData: [],
        model: {},
      }
    },
    computed:{
      items() {
        let arr = [
          {
            type: "tree-select",
            name: "上级菜单",
            key: "parentId",
            treeData: this.menuTreeData
          },
          {
            type: "input",
            name: "名称",
            key: "menuName",
            required: true,
          },
          {
            type: "input",
            name: "权限",
            key: "perms",
          },
          {
            type: "number",
            name: "排序",
            key: "sort",
          },
        ]
        if(this.type == 0){
          arr = arr.concat([
            {
              type: "input",
              name: "菜单URL",
              key: "path",
            },
            {
              type: "input",
              name: "组件地址",
              key: "component",
            },
            {
              type: "icon-select",
              name: "菜单图标",
              key: "icon",
            },
            {
              type: "checkbox",
              name: "隐藏菜单",
              key: "hidden",
              hint: "隐藏菜单，不在侧边栏显示"
            },
          ])
        }
        return arr
      }
    },

    methods: {
      show(type,menuId="0") {
        this.type = type
        this.edit = menuId>0

        this.model={
          type:parseInt(type),
          menuId:parseInt(menuId)
        }

        if (this.model.menuId > 0) {
          this.$get('menu/info/' + this.model.menuId).then(suc => {
            suc.data.parentId+=""
            this.model=suc.data
            this.model.createTime=null
            this.model.modifyTime=null
            this.$refs.form.show(this.model)
          })
        }

        this.$get('menu', {type: '0'}).then((r) => {
          this.menuTreeData = r.data.rows.children
        })

        this.$refs.form.show(this.model)

      }
    }
  }
</script>
